<template>
    <div>
        <section class="section center full is-vcentered">

            <div class="card">
                <h1>404 - Diese Seite wurde nicht gefunden.</h1>
                <p>
                    <router-link :to="'/'">Hier geht es zur Homepage.</router-link>
                </p>
            </div>

        </section>
    </div>

</template>

<script>
	export default {
		name: 'NotFound',
	}
</script>
